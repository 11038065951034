<template>
	<div class="d-flex min-vh-100 align-items-center">
		<CContainer>
			<CRow>
				<CCol>
					<div class="login-form">
						<CForm @submit.prevent="handleLogin">
							<h3 class="login-form-heading">
								{{ $t('page.login.login') }}
							</h3>
							<CInput
								v-model.trim="$v.username.$model"
								:is-valid="isUsernameValid"
								:invalid-feedback="errorUsernameText"
								class="login-form-input"
								placeholder="Username"
								autocomplete="username email"
								data-test-id="input-username"
								@input="clearLoginError"
							>
								<template #prepend-content>
									<CIcon name="cil-user" />
								</template>
							</CInput>
							<CInput
								v-model.trim="$v.password.$model"
								:is-valid="isPasswordValid"
								:invalid-feedback="errorPasswordText"
								class="login-form-input"
								placeholder="Password"
								type="password"
								autocomplete="curent-password"
								data-test-id="input-password"
								@input="clearLoginError"
							>
								<template #prepend-content>
									<CIcon name="cil-lock-locked" />
								</template>
							</CInput>
							<div class="text-center">
								<CButton
									:disabled="isLoading"
									type="submit"
									color="primary"
									class="submit-button px-4"
									data-test-id="button-submit"
								>
									{{ $t('page.login.login') }}
								</CButton>
							</div>
						</CForm>
					</div>
				</CCol>
			</CRow>

			<SocialGroup />
		</CContainer>
	</div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import SocialGroup from '@/components/social/SocialGroup.vue';
import { required } from 'vuelidate/lib/validators';
import { pathOr } from '../assets/js/helpers';
import errorMessage from '../enums/errorMessage';

export default {
	name: 'LoginPage',
	components: {
		SocialGroup,
	},
	data() {
		return {
			username: null,
			password: null,
		};
	},
	validations: {
		username: {
			required,
		},
		password: {
			required,
		},
	},
	computed: {
		...mapState('profile', {
			permissions: 'permissions',
		}),

		...mapState({
			isLoading: (state) => state.auth.isLoading,
			errorLogin: (state) => state.auth.error,
			errorLoginMessage: (state) => state.auth.errorMessage,
		}),

		...mapGetters({
			getDefaultRouteName: 'profile/getDefaultRouteName',
		}),

		errorUsername() {
			return this.errorLogin || pathOr(false, ['$v', 'username', '$error'])(this);
		},
		errorUsernameText() {
			const validateRequired = pathOr(false, ['$v', 'username', 'required'])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			}
			return null;
		},
		isUsernameValid() {
			// If error return false, if not return null
			return this.errorUsername ? false : null;
		},
		errorPassword() {
			return this.errorLogin || pathOr(false, ['$v', 'password', '$error'])(this);
		},
		errorPasswordText() {
			const validateRequired = pathOr(false, ['$v', 'password', 'required'])(this);

			if (!validateRequired) {
				return this.$t(errorMessage.REQUIRED_FIELD);
			} else if (this.errorLogin) {
				return this.$t(this.errorLoginMessage);
			}
			return null;
		},
		isPasswordValid() {
			// If error return false, if not return null
			return this.errorPassword ? false : null;
		},
	},
	methods: {
		...mapActions({
			postLogin: 'auth/postLogin',
			clearLoginError: 'auth/clearError',
			getProfile: 'profile/getProfile',
		}),
		async handleLogin() {
			this.$v.$touch();

			if (!this.$v.$invalid) {
				try {
					await this.postLogin({
						username: this.username,
						password: this.password,
					});

					if (!this.errorLogin) {
						await this.getProfile();

						const routeName = this.getDefaultRouteName;
						this.$router.push({ name: routeName });
					}
				} catch (error) {
					// TODO: handle error
				}
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.login-form {
	width: 444px;
	max-width: 100%;
	margin: 0 auto;

	// .login-form-heading
	&-heading {
		margin-bottom: 1em;
		text-align: center;
	}

	// .login-form-input
	&-input {
		margin-bottom: 20px;

		// overwrite hide append icon
		::v-deep .is-invalid {
			background-image: none;
		}
	}
}

.submit-button {
	min-width: 100%;
}
</style>
