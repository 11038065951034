<template>
	<CRow>
		<CCol>
			<div class="divider">or</div>

			<div class="social-group-list">
				<button
					v-for="(item, key) in SOCIAL_PROVIDER_GROUP_LIST"
					:key="key"
					class="btn btn-social"
					@click="onSocialLogin(item.provider)"
				>
					<div class="logo">
						<img :src="item.icon.url" :alt="item.icon.alt" loading="lazy">
					</div>
					<span class="label">
						{{ item.label }}
					</span>
				</button>
			</div>
		</CCol>
	</CRow>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { ROUTE_NAME } from '../../enums/route';
import {
	SOCIAL_PROVIDER_GROUP,
	SOCIAL_PROVIDER_GROUP_LIST,
	PROVIDER,
	PROVIDER_KEY,
	REGISTER_STATUS,
	SOCIAL_LOGIN_ERROR_TYPE,
} from '../../enums/social';

export default {
	name: 'SocialGroup',
	data() {
		return {
			PROVIDER,
			SOCIAL_PROVIDER_GROUP_LIST,
		};
	},
	computed: {
		...mapState('profile', ['profile']),

		...mapGetters({
			getDefaultRouteName: 'profile/getDefaultRouteName',
		}),
	},
	beforeDestroy() {
		window.removeEventListener('message', this.onReceivingMessage);
	},
	methods: {
		...mapActions({
			postSocialLogin: 'auth/postSocialLogin',
			clearLoginSocialError: 'auth/clearLoginSocialError',
			setUsername: 'auth/setUsername',
			showToast: 'toast/showToast',
			getProfile: 'profile/getProfile',
		}),

		async onReceivingMessage(event) {
			let token;
			try {
				token = JSON.parse(event.data);
			} catch {
				// event.data cannot be parsed as JSON, this message is not token
				return;
			}

			try {
				// 'message' event can be many things
				// we check if the JSON parsed obj having the key 'access_token'
				// to determine this is the message from CRM
				// eslint-disable-next-line camelcase
				if (token?.access_token) {
					await this.postSocialLogin({ token: token.access_token });

					await this.getProfile();

					// update username as staff ID
					localStorage.setItem('username', this.profile.data.staffId);
					this.setUsername();

					const routeName = this.getDefaultRouteName;
					this.$router.push({ name: routeName });
				}
			} catch (error) {
				if (error.response) {
					const { status } = error.response;
					const { registration_status: regsiterStatus, error: errorType, message } = error.response.data;
					switch (status) {
						// This user either:
						// 1) Not having account on the site yet
						// 2) Already having account on the site but not approved yet
						// 3) Already having account on the site and approved but account is inactive
						case 403:
							if (regsiterStatus === REGISTER_STATUS.UNVERIFIED || regsiterStatus === REGISTER_STATUS.REJECTED) {
								// 1)
								this.$router.push({
									name: ROUTE_NAME.REGISTER_SOCIAL,
									query: {
										provider: error.response.data.provider,
									},
									params: {
										crm_token: token.access_token,
										...error.response.data,
									},
								});
							} else if (regsiterStatus === REGISTER_STATUS.PENDING) {
								// 2)
								this.clearLoginSocialError();

								this.showToast({
									content: 'Waiting admin to approve your account',
									header: 'Pending',
									type: 'danger',
								});
							} else if (errorType === SOCIAL_LOGIN_ERROR_TYPE.ACCOUNT_INACTIVE) {
								this.showToast({
									content: message,
									header: 'Error',
									type: 'danger',
								});
							}

							break;
						case 401:
							this.$emit('error', error);
							break;
						default:
							break;
					}
				}
			}
		},
		onSocialLogin(provider) {
			let spec = '';

			switch (provider) {
				case PROVIDER_KEY[PROVIDER.IT_EASY]:
					spec = this.windowSpec(500, 500);
					break;
				default:
					break;
			}

			window.open(SOCIAL_PROVIDER_GROUP[provider].window.url, null, spec);
			// Setup message eventlistener to listen to token from CRM
			// We don't have to worry about duplicated eventlistener when user clicked this multiple times
			// Because we use reference to function, addEventListener automatically detect duplication here
			window.addEventListener('message', this.onReceivingMessage);
		},

		windowSpec(width = 500, height = 500) {
			const top = (window.innerHeight - height) / 2;
			const left = (window.innerWidth - width) / 2;

			return `width=${width},height=${height},top=${top},left=${left}`;
		},
	},
};
</script>

<style lang="scss" scoped>
.divider {
	width: 444px;
	margin-top: 24px;
	margin-left: auto;
	margin-right: auto;
	font-size: 14px;
	display: flex;
	align-items: center;

	&::before,
	&::after {
		flex: 1;
		content: "";
		padding: 0.5px;
		background-color: #ddd;
		margin: 10px 0 10px 10px;
	}

	&::before {
		margin: 10px 10px 10px 0;
	}
}

.social-group-list {
	width: 444px;
	margin-top: 24px;
	margin-left: auto;
	margin-right: auto;
	display: flex;
	justify-content: center;
}

.btn-social {
	display: flex;
	align-items: center;
	gap: 16px;

	width: 100%;
	border: 1px solid #ddd;
	transition: all 0.3s;
	border-radius: 4px;

	&:hover {
		border-color: #d0d0d0;
	}

	.logo img {
		width: 20px;
		height: 20px;
	}

	.label {
		font-size: rem(14);
		font-weight: 600;
		line-height: rem(16);
	}
}
</style>
